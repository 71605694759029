import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';

import ToggleFullscreen from '../Common/ToggleFullscreen';

class Header extends Component {

    toggleOffsidebar = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('offsidebarOpen');
    }

    toggleCollapsed = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('isCollapsed');
        this.resize()
    }

    toggleAside = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('asideToggled');
    }

    resize () {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }

    
    render() {
        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */ }
                <nav className="navbar topnavbar">
                    { /* START navbar header */ }
                    <div className="navbar-header">
                        <Link className="navbar-brand" to="/dashboard">
                            <div className="brand-logo">
                                <img className="img-fluid" src="img/logo-white.png" alt="App Logo" />
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid" src="img/logo-single-white.png" alt="App Logo" />
                            </div>
                        </Link>
                    </div>
                    { /* END navbar header */ }

                    { /* START Left navbar */ }
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            { /* Button used to collapse the left sidebar. Only visible on tablet and desktops */ }
                            <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={ this.toggleCollapsed }>
                                <em className="fas fa-bars"></em>
                            </a>
                            { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */ }
                            <a href=""  className="nav-link sidebar-toggle d-md-none" onClick={ this.toggleAside }>
                                <em className="fas fa-bars"></em>
                            </a>
                        </li>
                    </ul>
                    { /* END Left navbar */ }
                    { /* START Right Navbar */ }
                    <ul className="navbar-nav flex-row">
                        { /* START log out screen */ }
                            <li className="nav-item d-none d-md-block">
                                <Link to="/logout" title="Log Out" className="nav-link">
                                    <em className="fa fa-lock"></em> Log Out
                                </Link>
                            </li>
                        { /* END lock out screen */ }
                        { /* START Alert menu */ }
                        {/* <UncontrolledDropdown nav inNavbar className="dropdown-list">
                            <DropdownToggle nav className="dropdown-toggle-nocaret">
                                <em className="fa fa-user"></em>
                                <span className="badge badge-danger">1</span>
                            </DropdownToggle>
                            <DropdownMenu right className="dropdown-menu-right animated fadeIn">
                                <DropdownItem>
                                    <ListGroup>
                                       <ListGroupItem action tag="a" href="/account-settings">
                                          <div className="media">
                                                <div className="align-self-start mr-2">
                                                    <em className="fa fa-cog text-warning"></em>
                                                </div>
                                                <div className="media-body">
                                                    <p className="m-0">Account settings</p>
                                                </div>
                                          </div>
                                       </ListGroupItem>
                                       <ListGroupItem action tag="a" href="/view-invoice/all">
                                          <div className="media">
                                                <div className="align-self-start mr-2">
                                                    <em className="fa fa-receipt text-success"></em>
                                                </div>
                                                <div className="media-body">
                                                    <p className="m-0">Invoice</p>
                                                </div>
                                          </div>
                                       </ListGroupItem>
                                    </ListGroup>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown> */}
                        { /* END Alert menu */ }
                        { /* Fullscreen (only desktops) */ }
                        <li className="nav-item d-none d-md-block">
                            <ToggleFullscreen className="nav-link"/>
                        </li>
                        { /* START Offsidebar button */ }
                        <li className="nav-item">
                            <a className="nav-link" href="" onClick={this.toggleOffsidebar}>
                                <em className="fa fa-cogs"></em>
                            </a>
                        </li>
                        { /* END Offsidebar menu */ }
                    </ul>
                    { /* END Right Navbar */ }
                </nav>
                { /* END Top Navbar */ }
            </header>
            );
    }

}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);