import React, { Suspense, lazy } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { createBrowserHistory } from "history";
import { useAppContext } from "./libs/contextLib";

/* loader component for Suspense*/
import PageLoader from "./components/Common/PageLoader";

import Base from "./components/Layout/Base";
import BasePage from "./components/Layout/BasePage";
// import BaseHorizontal from './components/Layout/BaseHorizontal';

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));

const Login = lazy(() => import("./components/Pages/Login"));
const Recover = lazy(() => import("./components/Pages/Recover"));
const Logout = lazy(() => import("./components/Pages/Logout"));
const Update = lazy(() => import("./components/Pages/Update"));


const ViewCampaignHome = lazy(() =>
  import("./components/Campaign/ViewCampaignHome")
);

const TemplateHome = lazy(() => import("./components/Template/TemplateHome"));

const Gallery = lazy(() => import("./components/Gallery/Gallery"));

const ViewContactHome = lazy(() =>
  import("./components/Contact/ViewContactHome")
);

const Inbox = lazy(() => import("./components/Inbox/Inbox"));

const Insights = lazy(() => import("./components/Insights/Chart"));

const Settings = lazy(() => import("./components/Settings/Settings"));

const AccountSettings = lazy(() =>
  import("./components/Account/AccountSettings")
);
const ViewInvoiceHome = lazy(() =>
  import("./components/Invoice/ViewInvoiceHome")
);

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
  /* See full project for reference */
  "/login",
  "/recover",
  "/update"
];
const history = createBrowserHistory();
const Routes = ({ location }) => {
  const { isAuthenticated } = useAppContext();
  // console.log("routes", isAuthenticated);
  //console.log('routes');
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  if (path) {
    history.replace(path); //to remove #! from the url
    location.pathname = location.hash.split("!")[1];
  }

  const currentKey = location.pathname.split("/")[1] || "/";
  const timeout = { enter: 500, exit: 500 };

  // Animations supported
  //      'rag-fadeIn'
  //      'rag-fadeInRight'
  //      'rag-fadeInLeft'

  const animationName = "rag-fadeIn";

  function PrivateRoute({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
        }
      />
    );
  }
  if (listofPages.indexOf(location.pathname) > -1) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            {/* See full project for reference */}
            <Route path="/login" component={waitFor(Login)} />
            <Route path="/recover" component={waitFor(Recover)} />
            <Route path="/update" component={waitFor(Update)} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else {
    return (
      // Layout component wrapper
      // Use <BaseHorizontal> to change layout
      <Base>
        <TransitionGroup>
          <CSSTransition
            key={currentKey}
            timeout={timeout}
            classNames={animationName}
            exit={false}
          >
            <div>
              <Suspense fallback={<PageLoader />}>
                <Switch location={location}>
                  <PrivateRoute
                    path="/dashboard"
                    component={Dashboard}
                  ></PrivateRoute>

                  {/* <Route path="/" component={waitFor()} /> */}
                  <PrivateRoute
                    path="/campaign"
                    component={ViewCampaignHome}
                  ></PrivateRoute>

                  <PrivateRoute
                    path="/template"
                    component={TemplateHome}
                  ></PrivateRoute>

                  <PrivateRoute
                    path="/gallery"
                    component={Gallery}
                  ></PrivateRoute>

                  <PrivateRoute
                    path="/view-contact"
                    component={ViewContactHome}
                  ></PrivateRoute>

                  <PrivateRoute
                    path="/account-settings"
                    component={AccountSettings}
                  ></PrivateRoute>

                  <PrivateRoute
                    path="/view-invoice"
                    component={ViewInvoiceHome}
                  ></PrivateRoute>                  

                  <PrivateRoute
                    path="/inbox"
                    component={Inbox}
                  ></PrivateRoute>

                  <PrivateRoute
                    path="/insights"
                    component={Insights}
                  ></PrivateRoute>

                  <PrivateRoute
                    path="/settings"
                    component={Settings}
                  ></PrivateRoute>
              
                  <Route path="/logout" component={waitFor(Logout)} />

                  <Redirect to="/login" />
                </Switch>
              </Suspense>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Base>
    );
  }
};

export default withRouter(Routes);
