const Menu = [
    // {
    //     heading: 'Main Navigation',
    //     translate: 'sidebar.heading.HEADER'
    // },
    {
        name: 'Dashboard',
        path: '/dashboard',
        icon : 'fa fa-desktop',
        translate: 'sidebar.nav.DASHBOARD'
    },
    {
        name: 'Contacts',
        path: '/view-contact',
        icon : 'fa fa-address-book',
        translate: 'sidebar.nav.CONTACT'
    },
    {
        name: 'Templates',
        path: '/template',
        icon : 'far fa-object-group',
        translate: 'sidebar.nav.TEMPLATE'
    },
    {
        name: 'Campaigns',
        icon: 'fa fa-bullhorn',
        path: '/campaign',
        translate: 'sidebar.nav.CAMPAIGN'
    },
    {
        name: 'Insights',
        path: '/insights',
        icon : 'fa fa-chart-line',
        translate: 'sidebar.nav.INSIGHTS'
    }, 
    {
        name: 'Inbox',
        path: '/inbox',
        icon : 'fa fa-inbox',
        translate: 'sidebar.nav.INBOX'
    },
    {
        name: 'Settings',
        path: '/settings',
        icon : 'fa fa-cog',
        translate: 'sidebar.nav.SETTINGS'
    }
];

export default Menu;
