export default {
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://1xz2vdkak6.execute-api.us-east-1.amazonaws.com/test"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_Zsjm3fiIr",
      APP_CLIENT_ID: "5se8dgjb9dsmp8k18i5p12e1if",
      IDENTITY_POOL_ID: "us-east-1:7a16772c-1c02-4c37-96e4-d63e04bfe3e7"
    },
    s3: {
      REGION: "us-east-1",
      BUCKET: "ub-contact-upload-bucket-test"
    }
};